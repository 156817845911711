
import { Options, Vue } from "vue-class-component";

@Options({
  data() {
    return {
      text: undefined,
    };
  },
  components: {},
  created() {
    const { t } = this.$route.query;
    if (t === undefined) {
      this.text = "无法获取文本";
      return;
    }
    this.text = t;
  },
})
export default class HomeView extends Vue {}
